import React from "react"
import { Link } from "gatsby"
import { MediaQuery, useMediaQuery } from "../../../../utils/hooks/mediaQuery"
import { GatsbyImage } from "gatsby-plugin-image"
import { getTextBlockPosition } from "../../../../utils/projects"
import classNames from "classnames"
import * as styles from "./HomeProjectImageCard.module.scss"

const HomeProjectImageCard = ({ card }) => {
  const isDesktop = useMediaQuery(MediaQuery.Desktop)

  const cardImage = card.cardImage
  const cardImageMobile = card.cardImageMobile

  const textContainer = card?.textPosition
  const textPosition = getTextBlockPosition(textContainer)
  const bgColor = card.backgroundColor?.hex ? card.backgroundColor?.hex : null
  const textColor = card.textColor?.hex ? card.textColor?.hex : null
  const customWidth = card.customCardWidth > 0 ? card.customCardWidth : null
  const customLeftMargin =
    card.customLeftMargin > 0 ? card.customLeftMargin : null
  const customTopMargin = card.customTopMargin > 0 ? card.customTopMargin : null

  const textStyle =
    card.isTitleOverlaid && isDesktop
      ? {
          position: "absolute",
          width: `${textPosition.width}%`,
          // top: `${textPosition.top}%`,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }
      : {}

  const isLinked = card?.project?.slug?.current
    ? card?.project?.slug?.current
    : null

  return (
    <div
      style={{
        "--card-bg-color": bgColor,
        "--card-text-color": textColor,
        "--card-custom-width": `${customWidth}%`,
        "--card-custom-left-margin": `${customLeftMargin}%`,
        "--card-custom-top-margin": `${customTopMargin}%`,
      }}
      className={classNames(styles.cardItem, {
        [styles.cardItemFull]: card.isCardFullColumnWidth === true,
        [styles.cardItemBoxed]: card.isBoxedFormat === true,
        [styles.cardItemLeft]: card.leftOrRightAligned === "left",
        [styles.cardItemRight]: card.leftOrRightAligned === "right",
        [styles.cardItemBgColor]: card.backgroundColor?.hex,
        [styles.cardItemTextColor]: card.textColor?.hex,
        [styles.cardItemPatterned]: card.isBackgroundPatterned,
        [styles.cardItemCustomWidth]: customWidth,
        [styles.cardItemCustomTopMargin]: customTopMargin,
      })}
    >
      {isLinked ? (
        <Link to={`/projects/${card?.project?.slug?.current}`}>
          {isDesktop ? (
            <GatsbyImage
              image={cardImage?.image?.asset?.gatsbyImageData}
              alt={cardImage?.altText || card.name}
              loading="eager"
              className={classNames({
                [styles.websiteImage]: card.isWebsiteCard === true,
              })}
            />
          ) : (
            <GatsbyImage
              image={
                cardImageMobile?.image?.asset?.gatsbyImageData ||
                cardImage?.image?.asset?.gatsbyImageData
              }
              alt={cardImageMobile?.altText || cardImage?.altText || card.name}
              loading="eager"
              className={classNames({
                [styles.websiteImage]: card.isWebsiteCard === true,
              })}
            />
          )}
        </Link>
      ) : (
        <>
          {isDesktop ? (
            <GatsbyImage
              image={cardImage?.image?.asset?.gatsbyImageData}
              alt={cardImage?.altText || card.name}
              loading="eager"
              className={classNames({
                [styles.websiteImage]: card.isWebsiteCard === true,
              })}
            />
          ) : (
            <GatsbyImage
              image={
                cardImageMobile?.image?.asset?.gatsbyImageData ||
                cardImage?.image?.asset?.gatsbyImageData
              }
              alt={cardImageMobile?.altText || cardImage?.altText || card.name}
              loading="eager"
              className={classNames({
                [styles.websiteImage]: card.isWebsiteCard === true,
              })}
            />
          )}
        </>
      )}

      {card.displayTitle && (
        <div
          style={textStyle}
          className={classNames(styles.cardText, {
            [styles.cardTextOverlaid]: card.isTitleOverlaid,
          })}
        >
          {isLinked ? (
            <Link to={`/projects/${card?.project?.slug?.current}`}>
              <div>
                <h3>{card.name}</h3>
              </div>
              {card?.displayDate && (
                <div>
                  <h5>{card.displayDate}</h5>
                </div>
              )}
            </Link>
          ) : (
            <>
              <div>
                <h3>{card.name}</h3>
              </div>
              {card?.displayDate && (
                <div>
                  <h5>{card.displayDate}</h5>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export { HomeProjectImageCard }
