import React, { useMemo } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import { shuffle } from "../../../../utils/data"
import { MediaQuery, useMediaQuery } from "../../../../utils/hooks/mediaQuery"
import { HostedEmbed } from "../../../common/HostedEmbed"
import { GatsbyImage } from "gatsby-plugin-image"
import { getTextBlockPosition } from "../../../../utils/projects"
import * as styles from "./HomeProjectMediaCard.module.scss"

const HomeProjectMediaCard = ({ card }) => {
  const textContainer = card.textPosition
  const textPosition = getTextBlockPosition(textContainer)

  const bgColor = card.backgroundColor?.hex ? card.backgroundColor?.hex : null
  const textColor = card.textColor?.hex ? card.textColor?.hex : null

  const url = card.cardMediaUrl

  const textStyle = card.isTitleOverlaid
    ? {
        position: "absolute",
        width: `${textPosition.width}%`,
        top: `${textPosition.top}%`,
        // left: `${textPosition.left}%`,
        // top: "50%",
        left: "50%",
        // transform: `translate(-50%, -${textPosition.top}%)`,
        transform: "translate(-50%, -50%)",
      }
    : {}

  const isLinked = card.project?.slug?.current
    ? card.project?.slug?.current
    : null

  return (
    <div
      style={{
        "--card-bg-color": bgColor,
        "--card-text-color": textColor,
      }}
      className={classNames(styles.cardItem, {
        [styles.cardItemFull]: card.isCardFullColumnWidth === true,
        [styles.cardItemBoxed]: card.isBoxedFormat === true,
        [styles.cardItemLeft]: card.leftOrRightAligned === "left",
        [styles.cardItemRight]: card.leftOrRightAligned === "right",
        [styles.cardItemBgColor]: card.backgroundColor?.hex,
        [styles.cardItemTextColor]: card.textColor?.hex,
        [styles.cardItemPatterned]: card.isBackgroundPatterned,
      })}
    >
      <Link to={`/projects/${card.project?.slug?.current}`}>
        <HostedEmbed mediaUrl={url} />
      </Link>

      <div
        className={classNames(styles.cardText, {
          [styles.cardTextOverlaid]: card.isTitleOverlaid,
        })}
        style={textStyle}
      >
        {isLinked ? (
          <Link to={`/projects/${card.project?.slug?.current}`}>
            <div>
              <h3>{card.name}</h3>
            </div>
            {card?.displayDate && (
              <div>
                <h5>{card?.displayDate}</h5>
              </div>
            )}
          </Link>
        ) : (
          <>
            <div>
              <h3>{card.name}</h3>
            </div>
            {card?.displayDate && (
              <div>
                <h5>{card?.displayDate}</h5>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export { HomeProjectMediaCard }
