import React, { useMemo } from "react"
import { InternalExternalLink } from "../../../common/InternalExternalLink"
import { shuffle } from "../../../../utils/data"
import * as styles from "./HomeFeatures.module.scss"

const HomeFeatures = ({ features, numberFeatures }) => {
  const shuffledFeatures = useMemo(() => {
    return shuffle(features).slice(0, numberFeatures)
  }, [features])

  return (
    <div className={styles.features}>
      {shuffledFeatures.map((feature, index) => {
        return (
          <div key={`feature-${index}`} className={styles.feature}>
            <InternalExternalLink
              to={`/projects?curated=${feature?.slug?.current}`}
            >
              <h3 className={styles.featureHeadline}>
                <span>{feature.description}</span>
              </h3>
            </InternalExternalLink>
          </div>
        )
      })}
    </div>
  )
}

export { HomeFeatures }
