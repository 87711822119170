import React from "react"
import ReactMarkdown from "react-markdown"
import classNames from "classnames"
import SanityBlockContent from "@sanity/block-content-to-react"
import * as styles from "./ContactInfo.module.scss"

const ContactInfo = ({ name, telephone, email, _rawAddress, className }) => {
  return (
    <div className={classNames(styles.contactInfo, className)}>
      <div>
        <h2>ADDRESS</h2>
        <SanityBlockContent blocks={_rawAddress} />
      </div>
      <div>
        <h2>CONTACT</h2>
        <p>{telephone}</p>
        <p>{email}</p>
      </div>
    </div>
  )
}

export { ContactInfo }
