// extracted by mini-css-extract-plugin
export var cardItem = "HomeProjectImageCard-module--card-item--2bd1c";
export var cardItemBgColor = "HomeProjectImageCard-module--card-item-bg-color--2f97c";
export var cardItemBoxed = "HomeProjectImageCard-module--card-item-boxed--fe999";
export var cardItemCustomTopMargin = "HomeProjectImageCard-module--card-item-custom-top-margin--e4970";
export var cardItemCustomWidth = "HomeProjectImageCard-module--card-item-custom-width--b1a2d";
export var cardItemFull = "HomeProjectImageCard-module--card-item-full--24d94";
export var cardItemLeft = "HomeProjectImageCard-module--card-item-left--6bccb";
export var cardItemPatterned = "HomeProjectImageCard-module--card-item-patterned--df7a8";
export var cardItemRight = "HomeProjectImageCard-module--card-item-right--19c41";
export var cardItemTextColor = "HomeProjectImageCard-module--card-item-text-color--33466";
export var cardText = "HomeProjectImageCard-module--card-text--f7150";
export var cardTextOverlaid = "HomeProjectImageCard-module--card-text-overlaid--a9333";
export var header1 = "HomeProjectImageCard-module--header1--c9c93";
export var header2 = "HomeProjectImageCard-module--header2--99e9e";
export var header3 = "HomeProjectImageCard-module--header3--48e09";
export var header4 = "HomeProjectImageCard-module--header4--f1064";
export var header5 = "HomeProjectImageCard-module--header5--0d200";
export var websiteImage = "HomeProjectImageCard-module--website-image--1b14a";