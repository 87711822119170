import { getRandomArbitraryInt } from "./math"

export const getYearsAsString = years => {
  let yearsString = ""

  years.forEach(element => {
    yearsString += `${element.name} `
  })
  return yearsString
}

export const getItemsSizesPositions = block => {
  let arr = []

  const numInRow = block.numberItemsInRow
  const numProjects = block.projects?.length
  const maxTotalWidth = 95
  let maxItemWidth
  let minItemWidth
  if (numInRow === 2) {
    maxItemWidth = 60
    minItemWidth = 35
  }
  if (numInRow === 3) {
    maxItemWidth = 35
    minItemWidth = 25
  }

  // Only get positions if there are more than one in a row
  if (numInRow > 1) {
    for (let i = 0; i < numProjects; i += numInRow) {
      let tempPositions = []
      let totalWidth = 0

      // For each set in a row calculate positions
      for (let j = 0; j < numInRow; j++) {
        const max =
          maxItemWidth < maxTotalWidth - totalWidth
            ? maxItemWidth
            : maxTotalWidth - totalWidth
        const itemWidth = getRandomArbitraryInt(minItemWidth, max)
        totalWidth += itemWidth
        const imageWidth = 0.8 * itemWidth
        const leftMargin = (getRandomArbitraryInt(0, 15) / 100) * itemWidth
        const rightMargin = itemWidth - imageWidth - leftMargin
        tempPositions.push({
          width: imageWidth,
          leftMargin: leftMargin,
          rightMargin: rightMargin,
        })
      }

      arr.push(...tempPositions)
    }
  }
  return arr
}

export const getTextBlockPosition = textContainer => {
  let obj = {}
  if (textContainer) {
    const width = getRandomArbitraryInt(
      textContainer.minWidthPercentage,
      textContainer.maxWidthPercentage
    )

    const leftMargin =
      textContainer.leftPercentage +
      getRandomArbitraryInt(0, 100 - textContainer.rightPercentage - width)
    const topMargin = getRandomArbitraryInt(
      textContainer.topPercentage,
      100 - textContainer.bottomPercentage
    )

    obj = { width: width, left: leftMargin, top: topMargin }
  }
  return obj
}
