// extracted by mini-css-extract-plugin
export var block = "HomeProjects-module--block--de44a";
export var blockGrey = "HomeProjects-module--block-grey--cfc23";
export var centered = "HomeProjects-module--centered--7ad1e";
export var cover = "HomeProjects-module--cover--70c5d";
export var header1 = "HomeProjects-module--header1--a4afb";
export var header2 = "HomeProjects-module--header2--2149c";
export var header3 = "HomeProjects-module--header3--9c097";
export var header4 = "HomeProjects-module--header4--a9fe2";
export var header5 = "HomeProjects-module--header5--88930";
export var projectBlock = "HomeProjects-module--project-block--0e8b4";