import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import * as styles from "./HomeProjectTextCard.module.scss"

const HomeProjectTextCard = ({ card }) => {
  return (
    <>
      <div
        className={classNames(styles.cardText, styles.cardItem, {
          [styles.cardItemFull]: card.isCardFullColumnWidth === true,
          [styles.cardItemBoxed]: card.isBoxedFormat === true,
          [styles.cardItemLeft]: card.leftOrRightAligned === "left",
          [styles.cardItemRight]: card.leftOrRightAligned === "right",
          [styles.cardItemBgColor]: card.backgroundColor?.hex,
          [styles.cardItemTextColor]: card.textColor?.hex,
          [styles.cardItemPatterned]: card.isBackgroundPatterned,
        })}
      >
        <Link to={`/projects/${card?.project?.slug?.current}`}>
          <div>
            <h3>{card.name}</h3>
          </div>
          <div>
            <h5>{card.displayDate}</h5>
          </div>
        </Link>
      </div>
    </>
  )
}

export { HomeProjectTextCard }
