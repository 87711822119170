import React, { useMemo } from "react"
import classNames from "classnames"

import { MediaQuery, useMediaQuery } from "../../../../utils/hooks/mediaQuery"

import { HomeProjectsOneColumn } from "../HomeProjectsOneColumn"
import { HomeProjectsTwoColumn } from "../HomeProjectsTwoColumn"

import * as styles from "./HomeProjects.module.scss"

const HomeProjects = ({ projectBlocks, cardBlocks }) => {
  const isDesktop = useMediaQuery(MediaQuery.Desktop)

  console.log("cardBlocks ", cardBlocks)

  return (
    <div className={styles.projects}>
      {cardBlocks.map((item, index) => {
        // const itemsSizesPositions = getItemsSizesPositions(item)
        // const isFullWidth = item.format === "full" ? true : false

        const negativeTopMargin =
          item.negativeTopMargin > 0 ? item.negativeTopMargin : null

        return (
          <div
            style={{ "--block-margin-top": `-${negativeTopMargin}px` }}
            key={`item–${item.blockTitle}-${index}`}
            className={classNames(styles.block, {
              [styles.blockGrey]: item.numberColumnsInRow === 1,
            })}
          >
            <div
              className={classNames({
                [styles.cover]: item.format === "cover",
                [styles.centered]: item.format === "centered",
              })}
            >
              {item.numberColumnsInRow !== 2 && (
                <HomeProjectsOneColumn cards={item.cards} />
              )}
              {item.numberColumnsInRow === 2 && (
                <HomeProjectsTwoColumn cards={item.cards} />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export { HomeProjects }
