import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import { Wrapper } from "../components/layout/Wrapper"
import { AVCOContext } from "../components/context/AVCOContext"

import Seo from "../components/common/seo"

import { HomeProjects } from "../components/pagecomponents/HomePage/HomeProjects"
import { HomeFeatures } from "../components/pagecomponents/HomePage/HomeFeatures"
import { ContactInfo } from "../components/pagecomponents/ContactInfo"

import * as styles from "../styles/pages/Home.module.scss"

export const query = graphql`
  query IndexPageQuery {
    homepage: sanitySingletonHomepage {
      title
      description
      features {
        description
        slug {
          current
        }
      }
      numberHomeFeatures
      cardBlocks {
        blockTitle
        format
        numberColumnsInRow
        negativeTopMargin
        cards {
          name
          displayDate
          project {
            title
            displayDate
            slug {
              current
            }
          }
          type
          columnNumber
          isBoxedFormat
          isTitleOverlaid
          displayTitle
          isBackgroundPatterned
          isCardFullColumnWidth
          leftOrRightAligned
          backgroundColor {
            hex
          }
          textColor {
            hex
          }
          customCardWidth
          customLeftMargin
          customTopMargin
          cardMediaUrl
          isWebsiteCard
          cardImage {
            altText
            image {
              asset {
                gatsbyImageData(width: 1920, layout: FULL_WIDTH)
              }
            }
          }
          cardImageMobile {
            altText
            image {
              asset {
                gatsbyImageData(width: 1920, layout: FULL_WIDTH)
              }
            }
          }
          isMp4Portrait
          mp4Upload {
            source {
              asset {
                url
              }
            }
          }
          mp4UploadMobile {
            source {
              asset {
                url
              }
            }
          }

          textPosition {
            topPercentage
            rightPercentage
            bottomPercentage
            leftPercentage
            minWidthPercentage
            maxWidthPercentage
          }
        }
      }
      contact {
        name
        telephone
        email
        _rawAddress(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const description = data?.homepage?.description
  const features = data?.homepage?.features
  const numberFeatures = data?.homepage?.numberHomeFeatures
  const projectBlocks = data?.homepage?.projectBlocks
  const cardBlocks = data?.homepage?.cardBlocks
  const contact = data?.homepage?.contact

  const { activeMenuItem, setActiveMenuItem } = useContext(AVCOContext)

  useEffect(() => {
    setActiveMenuItem("avco")
  }, [activeMenuItem, setActiveMenuItem])

  return (
    <>
      <Seo title="Home" />

      <Wrapper narrow>
        <div className={styles.description}>
          <p>{description}</p>
        </div>
        <HomeFeatures features={features} numberFeatures={numberFeatures} />
      </Wrapper>
      <div className={styles.projectsWrapper}>
        <Wrapper full={true}>
          <HomeProjects projectBlocks={projectBlocks} cardBlocks={cardBlocks} />
        </Wrapper>
      </div>
    </>
  )
}

export default IndexPage
