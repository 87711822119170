import React, { useMemo } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import { shuffle } from "../../../../utils/data"
import { MediaQuery, useMediaQuery } from "../../../../utils/hooks/mediaQuery"
import { HostedEmbed } from "../../../common/HostedEmbed"
import { GatsbyImage } from "gatsby-plugin-image"
import { getTextBlockPosition } from "../../../../utils/projects"
import * as styles from "./HomeProjectMp4Card.module.scss"

const HomeProjectMp4Card = ({ card, isPortrait }) => {
  const isDesktop = useMediaQuery(MediaQuery.Desktop)

  const textContainer = card.textPosition
  const textPosition = getTextBlockPosition(textContainer)

  let mp4Source
  if (isDesktop) {
    mp4Source = card?.mp4Upload?.source.asset.url
  } else {
    if (card?.mp4UploadMobile?.source?.asset?.url) {
      mp4Source = card?.mp4UploadMobile?.source.asset.url
    } else {
      mp4Source = card?.mp4Upload?.source.asset.url
    }
  }

  const textStyle =
    card.isTitleOverlaid && isDesktop
      ? {
          position: "absolute",
          width: `${textPosition.width}%`,
          top: `${textPosition.top}%`,
          left: `${textPosition.left}%`,
          left: "50%",
          transform: `translate(-50%, -${textPosition.top}%)`,
        }
      : {}

  return (
    <>
      <Link to={`/projects/${card.project.slug.current}`}>
        <HostedEmbed mediaUrl={mp4Source} isPortrait={isPortrait} />
      </Link>

      <div
        className={classNames(styles.cardText, {
          [styles.cardTextOverlaid]: card.isTitleOverlaid,
        })}
        style={textStyle}
      >
        <Link to={`/projects/${card.project.slug.current}`}>
          <div>
            <h3>{card.name}</h3>
          </div>
          {card?.displayDate && (
            <div>
              <h5>{card?.displayDate}</h5>
            </div>
          )}
        </Link>
      </div>
    </>
  )
}

export { HomeProjectMp4Card }
