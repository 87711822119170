import React from "react"

import { Link } from "gatsby"
import PropTypes from "prop-types"

const InternalExternalLink = ({ to, children, ...props }) => {
  if (!to) {
    return <span {...props}>{children}</span>
  }

  if (to.startsWith("http") || to.startsWith("mailto")) {
    return (
      <a target="_blank" rel="noreferrer noopener" href={to} {...props}>
        {children}
      </a>
    )
  }

  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  )
}

// InternalExternalLink.propTypes = {
//   to: PropTypes.string.isRequired,
// }

export { InternalExternalLink }
