// extracted by mini-css-extract-plugin
export var cardItem = "HomeProjectMediaCard-module--card-item--6841b";
export var cardItemBgColor = "HomeProjectMediaCard-module--card-item-bg-color--f3766";
export var cardItemBoxed = "HomeProjectMediaCard-module--card-item-boxed--ff74d";
export var cardItemCustomTopMargin = "HomeProjectMediaCard-module--card-item-custom-top-margin--33da0";
export var cardItemCustomWidth = "HomeProjectMediaCard-module--card-item-custom-width--e60bc";
export var cardItemFull = "HomeProjectMediaCard-module--card-item-full--76795";
export var cardItemLeft = "HomeProjectMediaCard-module--card-item-left--03933";
export var cardItemPatterned = "HomeProjectMediaCard-module--card-item-patterned--2fadd";
export var cardItemRight = "HomeProjectMediaCard-module--card-item-right--90794";
export var cardItemTextColor = "HomeProjectMediaCard-module--card-item-text-color--76dff";
export var cardText = "HomeProjectMediaCard-module--card-text--cb625";
export var cardTextOverlaid = "HomeProjectMediaCard-module--card-text-overlaid--4eda5";
export var header1 = "HomeProjectMediaCard-module--header1--2f484";
export var header2 = "HomeProjectMediaCard-module--header2--db6fd";
export var header3 = "HomeProjectMediaCard-module--header3--d7a3d";
export var header4 = "HomeProjectMediaCard-module--header4--df830";
export var header5 = "HomeProjectMediaCard-module--header5--149e5";